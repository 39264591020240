import React from "react";

const ArrowIcon = ({rotateDeg}) => {
  return (
    <svg style={{transform: `rotate(${rotateDeg}deg)`}}
      fill="#000000"
      height="50px"
      width="50px"
      viewBox="0 0 485 485"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5 s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026 C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5 S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z" />
        <polygon points="193.228,135.589 299.791,242.5 193.228,349.411 214.476,370.589 342.148,242.5 214.476,114.411" />
      </g>
    </svg>
  );
};

export default ArrowIcon;
